/** ***************************************************************************
 * App / Atoms / Button / Close
 *************************************************************************** */

.lm-c-button.lm-m-close {
  --lm-c-button--m-close--Color: white;
  --lm-c-button--m-close--Dimension: 2.25rem;
  --lm-c-button--m-close--Border-Width: 2px;
  --lm-c-button--m-close--Border-Color: rgba(255, 255, 255, 0.6);
  --lm-c-button--m-close--Border-Radius: 1.25rem;
  --lm-c-button--m-close--Background-Color: var(--lm-global--palette-blue);
  background: var(--lm-c-button--m-close--Background-Color);
  padding: .688rem; // 11px

  border: var(--lm-c-button--m-close--Border-Width) solid var(--lm-c-button--m-close--Border-Color);
  border-radius: var(--lm-c-button--m-close--Border-Radius);
  position: relative;

  > svg {
    display: block;
    color: var(--lm-c-button--m-close--Color);
    width: .625rem; // 10px
  }

  .highlight {
    --highlight-offset: 3px;
    position: absolute;
    top: calc((var(--highlight-offset) + var(--lm-c-button--m-close--Border-Width)) * -1);
    left: calc((var(--highlight-offset) + var(--lm-c-button--m-close--Border-Width)) * -1);
    width: calc(var(--lm-c-button--m-close--Dimension) + (var(--highlight-offset) * 2));
    height: calc(var(--lm-c-button--m-close--Dimension) + (var(--highlight-offset) * 2));
    border-radius: var(--lm-c-button--m-close--Border-Radius);
    background-color: var(--lm-global--palette-dusty-blue--51);
    z-index: -1;
    filter: blur(5px);
    animation: lm-c-close__button--Pulse var(--lm-global--Duration--xl) linear infinite alternate;
  }

  &--blue {
    --lm-c-button--m-close--Color: white;
    --lm-c-button--m-close--Background-Color: var(--lm-global--palette-light-blue);
    border: 1px solid rgba(255, 255, 255, 0.49);
  }

  &--icon {
    --lm-c-button--m-close--Border-Color: white;
    --lm-c-button--m-close--Color: var(--lm-global--palette-dark-blue);
    --lm-c-button--m-close--Background-Color: #aec9dc;  //  was var(--lm-global--palette-light-blue--26)

    .highlight {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &--pb {
    --lm-c-button--m-close--Color: white;
    --lm-c-button--m-close--Background-Color: var(--lm-global-common--palette-kiss);
    border: 1px solid var(--lm-global-common--palette-kiss);
  }

  @keyframes lm-c-close__button--Pulse {
    from, 30% {
      opacity: 1;
    }

    to {
      opacity: 0;
    }

    // to {
    // }
  }
}
