/******************************************************************************
 * Common / Atoms / Button / Play
 *****************************************************************************/

.lm-c-button-play {
  --lm-c-button-play--BackgroundColor: var(--lm-global--palette-sky-blue);
  --lm-c-button-play--hover--BackgroundColor: rgb(204, 244, 255);
  --lm-c-button-play--Color: var(--lm-global--palette-dark-blue);
  --lm-c-button-play--FontSize: .5rem; /* 8px */
  --lm-c-button-play--Padding: 9px 19px;  /* 24px */
  --lm-c-button-play--BorderRadius: 1.438em;  /* 23px */
}


/**
 * Component
 */

.lm-c-button.lm-m-primary.lm-m-outline.lm-c-button-play {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;

  background-color: var(--lm-c-button-play--BackgroundColor);
  color: var(--lm-c-button-play--Color);
  border-radius: 50%;
  height: 104px;
  width: 104px;


  .inner {
    border: 1px solid var(--lm-c-button-play--Color);
    border-radius: var(--lm-c-button-play--BorderRadius);
    padding: var(--lm-c-button-play--Padding);
    color: var(--lm-c-button-play--Color);
  }

  &:before {
    content: none;
  }

  &:hover {
    background-color: var(--lm-c-button-play--hover--BackgroundColor);
  }

  &.lm-c-button-play--pb {
    --lm-c-button-play--BackgroundColor: var(--lm-global-common--palette-kiss);
    --lm-c-button-play--Color: var(--lm-global--palette-white);
    --lm-c-button-play--hover--BackgroundColor: rgb(224, 78, 104);
  }

  @media all and (min-width: 48rem) { // 768px
    height: 160px;
    width: 160px;
  }
}
