/******************************************************************************
 * Common / Atoms / Button / Base
 *****************************************************************************/

.lm-c-button {
  --lm-c-button--BackgroundColor: var(--lm-global--palette-dark-blue);
  --lm-c-button--BorderRadius: unset;  /* pill */
  --lm-c-button--Color: var(--lm-global--palette-sky-blue);
  --lm-c-button--Font: var(--lm-global--Font-label--xs-medium);
  --lm-c-button--PaddingBlock: .563rem;  /* 9px */
  --lm-c-button--PaddingInline: 1.563rem;  /* 25px */

  --lm-c-button--disabled--BackgroundColor: var(--lm-global--palette-gray-dark);
  --lm-c-button--disabled--Color: var(--lm-global--palette-dark-blue);
}



/**
 * Component
 * 1: solve a priority problem
 */

.lm-c-button,
a.lm-c-button,  /* 1 */
button.lm-c-button {  /* 1 */
  background-color: var(--lm-c-button--BackgroundColor);
  border-radius: var(--lm-c-button--BorderRadius);
  border: var(--lm-c-button--Border);
  border-color: var(--lm-c-button--BorderColor);
  box-sizing: border-box;
  color: var(--lm-c-button--Color);
  display: inline-block;
  font: var(--lm-c-button--Font);
  justify-self: start;
  padding: var(--lm-c-button--PaddingBlock) var(--lm-c-button--PaddingInline);
  text-align: center;
  transition:
    background-color .1s linear,
    color .1s linear;
}

/*
.lm-c-button:hover {
  background-color: var(--lm-c-button--hover--BackgroundColor, var(--lm-c-button--Color));
  border-color: var(--lm-c-button--hover--BorderColor, var(--lm-c-button--hover--BackgroundColor, var(--lm-c-button--Color)));
  color: var(--lm-c-button--hover--Color, var(--lm-c-button--BackgroundColor));
}

.lm-c-button:disabled {
  background-color: var(--lm-c-button--disabled--BackgroundColor, var(--lm-c-button--Color));
  border-color: var(--lm-c-button--disabled--BorderColor, var(--lm-c-button--hover--BackgroundColor, var(--lm-c-button--Color)));
  color: var(--lm-c-button--disabled--Color, var(--lm-c-button--BackgroundColor));
  cursor: default;
}

.lm-c-button:active {
  background-color: var(--lm-c-button--Color, var(--lm-c-button--hover--BackgroundColor, var(--lm-c-button--Color)));
  border-color: var(--lm-c-button--active--BorderColor, var(--lm-c-button--hover--BorderColor, var(--lm-c-button--hover--BackgroundColor, var(--lm-c-button--Color))));
  color: var(--lm-c-button--BackgroundColor, var(--lm-c-button--hover--Color, var(--lm-c-button--BackgroundColor)));
} */
