/** ***************************************************************************
 * Website / Organisms / Modal
 *************************************************************************** */

.lm-c-pb-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  touch-events: none;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-open {
    pointer-events: auto;
    touch-events: auto;
    visibility: visible;
    opacity: 1;
    transition: visibility, opacity .5s linear;
    z-index: 9999999;
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--lm-global--palette-dark-blue);
    opacity: .97;

    @media all and (min-width: 64rem) {
      cursor: none;
    }
  }

  .main {
    // background-color: var(--lm-c-page--BackgroundColor);
    width: 95%;
    height: auto;
    position: absolute;

    .lm-c-video {
      max-height: unset;

      .video-js {
        max-width: unset !important;
      }

      .lm-c-button-play {
        display: none !important;
      }
    }

    @media all and (min-width: 64rem) {
      width: 80%;
    }

    @media (orientation: landscape) and (max-width: 64rem) {
      width: 70%;
    }
  }

  .lm-c-button.lm-m-close--pb {
    display: none;
    margin: 0 auto;
    top: -4.5rem;
    pointer-events: none;
    touch-events: none;
    padding: 1.75rem;
    border-radius: 50%;

    @media (pointer:coarse) {
      display: block;
    }

    @media all and (min-height: 64rem) {
      top: -6.5rem;
    }

    @media (orientation: landscape) and (max-height: 37.5rem) { // 600px
      position: absolute;
      top: 0;
      right: -6rem;
    }
  }

}
