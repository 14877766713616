/** ***************************************************************************
 * Common / Atoms / Button / Primary
 *************************************************************************** */

.lm-c-button {
  --lm-c-button--m-primary--BackgroundColor: var(--lm-global--palette-dark-blue);
  --lm-c-button--m-primary--BorderColor: var(--lm-c-button--m-primary--BackgroundColor);
  --lm-c-button--m-primary--BorderRadius: 1.438em;  /* 23px */
  --lm-c-button--m-primary--Color: white;

  --lm-c-button--m-primary--hover--BackgroundColor: var(--lm-global--palette-gray-dark);
  --lm-c-button--m-primary--hover--Color: var(--lm-c-button--m-primary--Color);

  --lm-c-button--m-primary--m-outline--BackgroundColor: transparent;
  --lm-c-button--m-primary--m-outline--BorderColor: var(--lm-global--palette-bright-light-blue);
  --lm-c-button--m-primary--m-outline--Color: var(--lm-global--palette-sky-blue);
  --lm-c-button--m-primary--m-outline--TransitionDuration: var(--lm-global--Duration--xs);

  --lm-c-button--m-primary--m-outline--hover--Color: var(--lm-global--palette-dark-blue);;

  --lm-c-button--m-primary--m-outline-light-blue--BackgroundColor: transparent;
  --lm-c-button--m-primary--m-outline-light-blue--Color: var(--lm-global--palette-light-blue);
  --lm-c-button--m-primary--m-outline-light-blue--TransitionDuration: var(--lm-global--Duration--xs);
}



/**
 * Component
 */

.lm-c-button.lm-m-primary {
  --lm-c-button--BackgroundColor: var(--lm-c-button--m-primary--BackgroundColor);
  --lm-c-button--BorderRadius: var(--lm-c-button--m-primary--BorderRadius);
  --lm-c-button--BorderColor: var(--lm-c-button--m-primary--BorderColor);
  --lm-c-button--Color: var(--lm-c-button--m-primary--Color);
  --lm-c-button--hover--BackgroundColor: var(--lm-c-button--m-primary--hover--BackgroundColor);
  --lm-c-button--hover--Color: var(--lm-c-button--m-primary--hover--Color);
  position: relative;
  background-image: linear-gradient(-63.434948822921996deg, rgba(0, 139, 194, 1) 0%, rgba(0, 83, 143, 1) 100%);
  font-size: .813rem;
  z-index: 0;
}
.lm-c-button.lm-m-primary:before {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0; left: 0;
  background-image: linear-gradient(-63.434948822921996deg, rgba(0, 161, 224, 1) 0%, rgba(0, 72, 125, 1) 100%);
  border-radius: inherit;
  z-index: -100;
  opacity: 0;
  transition: opacity var(--lm-global--Duration--xs) linear;
}

.lm-c-button.lm-m-primary:hover:before,
.lm-c-button.lm-m-primary:active:before,
.lm-c-button.lm-m-primary.lm-m-active:before {
  opacity: 1;
}

.lm-c-button.lm-m-primary.lm-m-disabled {
  pointer-events: none;
}



.lm-c-button.lm-m-primary.lm-m-outline {
  --lm-c-button--Border: 1px solid var(--lm-c-button--m-primary--BorderColor);
  --lm-c-button--m-primary--BackgroundColor: var(--lm-c-button--m-primary--m-outline--BackgroundColor);
  --lm-c-button--m-primary--BorderColor: var(--lm-c-button--m-primary--m-outline--BorderColor);
  --lm-c-button--m-primary--Color: var(--lm-c-button--m-primary--m-outline--Color);
  --lm-c-button--m-primary--hover--Color: var(--lm-c-button--m-primary--m-outline--BackgroundColor);
  background: transparent;
  transition:
    background-color var(--lm-c-button--m-primary--m-outline--TransitionDuration) linear,
    color var(--lm-c-button--m-primary--m-outline--TransitionDuration) linear;
}

.lm-c-button.lm-m-primary.lm-m-outline:before {
  background-image: none;
  background-color: var(--lm-c-button--m-primary--BorderColor, var(--lm-c-button--m-primary--hover--BackgroundColor, var(--lm-c-button--m-primary--Color)));
}

.lm-c-button.lm-m-primary.lm-m-outline:hover,
.lm-c-button.lm-m-primary.lm-m-outline:active,
.lm-c-button.lm-m-primary.lm-m-outline.lm-m-active {
  color: var(--lm-c-button--m-primary--m-outline--hover--Color, var(--lm-c-button--m-primary--hover--Color, var(--lm-c-button--m-primary--BackgroundColor)));
}

.lm-c-button.lm-m-primary.lm-m-outline.lm-m-disabled {
  --lm-c-button--m-primary--BorderColor: var(--lm-global--palette-bright-light-blue--30);
  --lm-c-button--m-primary--Color: var(--lm-global--palette-bright-light-blue--80);
}



.lm-c-button.lm-m-primary.lm-m-outline--light-blue {
  --lm-c-button--m-primary--BackgroundColor: var(--lm-c-button--m-primary--m-outline-light-blue--BackgroundColor);
  --lm-c-button--Border: 1px solid var(--lm-c-button--m-primary--m-outline-light-blue--Color);
  --lm-c-button--m-primary--BorderColor: var(--lm-c-button--m-primary--m-outline-light-blue--Color);
  --lm-c-button--m-primary--Color: var(--lm-c-button--m-primary--m-outline-light-blue--Color);
  --lm-c-button--m-primary--hover--Color: var(--lm-c-button--m-primary--m-outline-light-blue--BackgroundColor);
  background: transparent;
  transition:
    background-color var(--lm-c-button--m-primary--m-outline--TransitionDuration) linear,
    color var(--lm-c-button--m-primary--m-outline--TransitionDuration) linear;
}

.lm-c-button.lm-m-primary.lm-m-outline--light-blue:before,
.lm-c-button.lm-m-primary.lm-m-outline--light-blue:hover,
.lm-c-button.lm-m-primary.lm-m-outline--light-blue:active,
.lm-c-button.lm-m-primary.lm-m-outline--light-blue.lm-m-active {
  background: none;
  background-color: var(--lm-c-button--m-primary--BorderColor, var(--lm-c-button--m-primary--hover--BackgroundColor, var(--lm-c-button--m-primary--Color)));
  color: white;
}

.lm-c-button.lm-m-primary.lm-m-outline--light-blue.lm-m-disabled {
  --lm-c-button--m-primary--BorderColor: var(--lm-global--palette-light-blue--30);
  --lm-c-button--m-primary--Color: var(--lm-global--palette-light-blue--80);
}
