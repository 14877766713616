/******************************************************************************
 * Common / Atoms / Credits
 *****************************************************************************/

.lm-c-credits {
  --fluid-31-39: clamp(1.9375rem, 1.8161rem + 0.5178vi, 2.4375rem);
  --fluid-20-3367: clamp(1.25rem, 1.0426rem + 0.8848vi, 2.1044rem);
  // position: absolute;
  // right: var(--fluid-31-39);
  // bottom: var(--fluid-20-3367);
  // font: var(--ap-global--Font-label--md-medium);
  font-family: 'EuclidCircularB';
  // font-size: 1.125rem;
  display: inline-flex;
  gap: .375rem;
  align-items: center;

  span {
    --fluid-1425-2533: clamp(0.8906rem, 0.0003rem + 1.319vi, 1.5831rem);
    width: 1.188rem;
    height: 1.188rem;
    animation: spin 3s linear infinite;

    svg {
      width: 1.188rem;
      height: 1.188rem;

      @media all and (min-width: 67.5rem) {
        width: var(--fluid-1425-2533);
        height: var(--fluid-1425-2533);
      }
    }

    @media all and (min-width: 67.5rem) {
      width: var(--fluid-1425-2533);
      height: var(--fluid-1425-2533);
    }
  }


  @media all and (min-width: 67.5rem) {
    font-size: var(--step-0);
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
