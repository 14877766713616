/** ***************************************************************************
 * Website / Atoms / Cursor
 *************************************************************************** */

@use 'node_modules/utopia-core-scss/src/utopia' as utopia;

.lm-c-cursor {
  --lm-c-cursor__dimension: 1rem;
  --lm-c-cursor__color: var(--lm-c-page--Color);


  // top: 0;
  // left: 0;
  // position: fixed;
  // z-index: 1000;
  // transition: transform .1s cubic-bezier(0.25, 1, 0.5, 1);
  // mix-blend-mode: lighten;
  // will-change: transform;

  &.dark {
    .cursor-shape.cursor-double {
      mix-blend-mode: difference;
      + .cursor-shape {
        mix-blend-mode: difference;
      }
    }
  }

  .cursor-shape {
    display: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: var(--lm-c-cursor__dimension);
    height: var(--lm-c-cursor__dimension);
    border-radius: 50%;
    translate: -50% -50%;
    transition: all .3s cubic-bezier(0.25, 1, 0.5, 1);
    // filter: blur(2px);
    background-color: var(--lm-c-cursor__color);
    z-index: 101;
    position: fixed;
    left: 0;
    top: 0;
    will-change: transform;

    .lm-c-button-icon,
    .lm-m-close--pb {
      opacity: 0;
    }

    &.cursor-double {
      background-color: var(--lm-c-page--BackgroundColor);
      mix-blend-mode: exclusion;
      z-index: 100;

      + .cursor-shape {
        mix-blend-mode: lighten;
      }
    }

    &.cursor--initialized {
      // if cursor initialized
      display: block;
      z-index: 99999999;
    }

    &.cursor--off-screen {
      // if true cursor is outside of the window
      opacity: 0;
    }

    &.cursor--focused {
      // if true cursor is hovering over a focusElement
      &.cursor-shape, &.cursor-base {
        @include utopia.generateClamps((
          "minWidth": 1024,
          "maxWidth": 1440,
          "pairs": (
            (32, 40),
          ),
          "prefix": "fluid-",
        ));
        --lm-c-cursor__dimension: var(--fluid-32-40);
      }
      &.cursor-shape {
        opacity: .15;
      }
    }

    &.cursor--focused-lg {
      &.cursor-shape, &.cursor-base {
        @include utopia.generateClamps((
          "minWidth": 1024,
          "maxWidth": 1440,
          "pairs": (
            (45, 58),
          ),
          "prefix": "fluid-",
        ));
        --lm-c-cursor__dimension: var(--fluid-45-58);
        opacity: .2;
      }
    }

    &.cursor--focused-xl {
      &.cursor-shape, &.cursor-base {
        @include utopia.generateClamps((
          "minWidth": 1024,
          "maxWidth": 1440,
          "pairs": (
            (52, 63),
          ),
          "prefix": "fluid-",
        ));
        --lm-c-cursor__dimension: var(--fluid-52-63);
        opacity: .15;
      }
    }

    &.big-cursor--focused-view {
      &.cursor-shape, &.cursor-base {
        width: calc(var(--lm-c-cursor__dimension) * 3);
        height: calc(var(--lm-c-cursor__dimension) * 3);
        mix-blend-mode: unset !important;
      }

      .text {
        opacity: 1;
        transition: opacity 360ms cubic-bezier(0.23, 1, 0.32, 1);
        color: var(--lm-c-page--BackgroundColor);
      }
    }

    &.cursor--disabled {
      // if cursor has been disabled
      opacity: 0;
    }

    &.cursor--pulsing-oval {
      &.cursor-shape, &.cursor-base {
        @include utopia.generateClamps((
          "minWidth": 1024,
          "maxWidth": 1440,
          "pairs": (
            (35, 50),
          ),
          "prefix": "fluid-",
        ));
        width: var(--fluid-35-50);
        height: var(--fluid-35-50);
      }
    }

    &.cursor--focused-video {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (190, 267),
          (10, 14),
        ),
        "prefix": "fluid-",
      ));
      --fluid-192-27: clamp(1.2rem, 0.6429rem + 1.1607vi, 1.6875rem);
      --lm-c-cursor__dimension: var(--fluid-190-267);

      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--lm-c-page--BackgroundColor);
      font: var(--lm-global--Font-label--md-medium);
      mix-blend-mode: unset !important;

      .text-container,
      .lm-m-close--pb {
        display: none;
      }
      .lm-c-button-icon {
        opacity: 1;
        transition: all .3s cubic-bezier(0.25, 1, 0.5, 1);
        padding: var(--fluid-10-14) var(--fluid-192-27);
      }
    }

    &.cursor--backdrop {
      @include utopia.generateClamps((
        "minWidth": 1024,
        "maxWidth": 1440,
        "pairs": (
          (20, 28),
          (34, 40),
        ),
        "prefix": "fluid-",
      ));
      --lm-c-cursor__dimension: var(--fluid-34-40);
      mix-blend-mode: unset !important;
      background-color: transparent;

      .text-container,
      .lm-c-button-icon {
        display: none;
      }
      .lm-m-close--pb {
        opacity: 1;
        border-radius: 50%;
        padding: var(--fluid-20-28);
      }
    }

    &.cursor--pre_footer {
      --lm-c-cursor__color: var(--lm-global--palette-aqua);

      &.cursor-double {
        background-color: var(--lm-global--palette-dark-blue);
        mix-blend-mode: difference;
        + .cursor-shape {
          mix-blend-mode: difference;
        }
      }
    }

    &.cursor--default {
      display: none;
    }
  }

  .text-container {
    // position: absolute;
    // left: 0;
    // top: 0;
    // translate: -50% -50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
  }

  .text {
    font-size: .875rem;
    color: var(--lm-global--palette-dark-blue);
    opacity: 0;
    -webkit-transition: opacity 80ms cubic-bezier(.23,1,.32,1);
    -o-transition: opacity 80ms cubic-bezier(.23,1,.32,1);
    transition: opacity 80ms cubic-bezier(.23,1,.32,1);
  }


}
