/******************************************************************************
 * Common / Molecules / Video
 *****************************************************************************/

.lm-c-video {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  max-height: 555px;
  aspect-ratio: 16/9;

  .video-js {
    position: absolute;
    max-width: 1200px !important;
    height: 100% !important;
    aspect-ratio: 16/9 !important;
    padding-top: 0 !important;

    picture.vjs-poster {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .vjs-big-play-button {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .video-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);

    .lm-c-button.lm-m-primary.lm-m-outline.lm-c-button-play {
      display: none;
    }

    &.has-poster {
      .lm-c-button.lm-m-primary.lm-m-outline.lm-c-button-play {
        display: flex;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .lm-c-loader {
      display: none;
    }

    &.is-loading {
      .lm-c-button.lm-m-primary.lm-m-outline.lm-c-button-play {
        display: none;
      }
      .lm-c-loader {
        display: block;
      }
    }

    &:focus,
    &:hover {
      .lm-c-button.lm-c-button-play {
        background-color: var(--lm-c-button-play--hover--BackgroundColor);
        transition: all 0s;
      }
    }
  }

  #vimeo-video {
    display: none;
    max-width: 100%;
    max-height: 34.063rem;

    /** Small */
    @media all and (min-width: 67.5rem) {
      max-height: 46.75rem;
      grid-column: 1/-1;
    }

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
