/******************************************************************************
 * Common / Atoms / Button / Icon / Base
 *****************************************************************************/

.lm-c-button-icon {
  --lm-c-button-icon--ColumnGap: .38em;  /* 5px */
  --lm-c-button-icon--PaddingBlock: .77em;  /* 10px */
  --lm-c-button-icon--PaddingInline: 2em;  /* 26px */
  --lm-c-button-icon--TransitionDuration: .15s;

  --lm-c-button-icon--hover__content--Color: var(--lm-c-page--BackgroundColor);
}


/**
 * Component
 * 1: pill
 */

.lm-c-button-icon,
button.lm-c-button-icon {
  align-items: center;
  border-radius: 100vmax;  /* 1 */
  border: 1px solid currentcolor;
  column-gap: var(--lm-c-button-icon--ColumnGap);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-block: var(--lm-c-button-icon--PaddingBlock);
  padding-inline: var(--lm-c-button-icon--PaddingInline);
  transition: background-color var(--lm-c-button-icon--TransitionDuration) linear;

  &:hover {
    background-color: currentcolor;

    > * {
      color: var(--lm-c-button-icon--hover__content--Color);
      transition: color var(--lm-c-button-icon--TransitionDuration) linear;
    }
  }
}



/**
 * Icon
 */

.lm-c-button-icon svg {
  block-size: 1em;
}
